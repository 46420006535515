
const config = {
  gatsby: {
    pathPrefix: '/',
    siteUrl: 'https://sanjeevkumarrao.com',
    gaTrackingId: 'G-0GR0EZMHT8',
    trailingSlash: false,
  },
  header: {
    logo: '/skb.svg',
    logoLink: 'https://sanjeevkumarrao.com',
    title:
      "<a href='https://sanjeevkumarrao.com'><img class='img-responsive' src='/sanjeev-blog-logo-white.svg' alt='site logo' /></a>",
    githubUrl: 'https://github.com/sanjeevkumarraob/local-kong',
    helpUrl: '',
    tweetText: '',
    links: [{ text: '', link: '' }],
    search: {
      enabled: false,
      indexName: '',
      algoliaAppId: process.env.GATSBY_ALGOLIA_APP_ID,
      algoliaSearchKey: process.env.GATSBY_ALGOLIA_SEARCH_KEY,
      algoliaAdminKey: process.env.ALGOLIA_ADMIN_KEY,
    },
  },
  sidebar: {
    forcedNavOrder: [
      '/kong-setup', // add trailing slash if enabled above
      '/devops-journey',
    ],
    collapsedNav: [
      '/devops-journey', // add trailing slash if enabled above
    ],
    links: [
      // { text: '&copy; sanjeevkumarrao.com', link: 'https://hasura.io' },
      // { text: '&copy; sanjeevkumarrao.com', link: 'https://hasura.io' },
    ],
    frontLine: false,
    ignoreIndex: true,
    title: 'Sharing my tech Journey',
  },
  siteMetadata: {
    title: "Sanjeev's Tech Bites | SKB",
    description: 'Sanjeev Kumar Badrinath - Sharing my tech journey',
    ogImage: null,
    docsLocation: 'https://github.com/hasura/gatsby-gitbook-boilerplate/tree/master/content',
    favicon: 'images/favicon.ico',
  },
  pwa: {
    enabled: false, // disabling this will also remove the existing service worker.
    manifest: {
      name: 'Gatsby Gitbook Starter',
      short_name: 'GitbookStarter',
      start_url: '/',
      background_color: '#6b37bf',
      theme_color: '#6b37bf',
      display: 'standalone',
      crossOrigin: 'use-credentials',
      icons: [
        {
          src: 'src/pwa-512.png',
          sizes: `512x512`,
          type: `image/png`,
        },
      ],
    },
  },
};

module.exports = config;
